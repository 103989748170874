import { enableScrollLock, disableScrollLock, enable } from '../utils/scrollLock';
export let mapSetup = false;

const adItemObjects = [];
const currentUrl = window.location.toString();
const searchOnLoad = !!(currentUrl.indexOf('freeText') != -1); /*|| currentUrl.indexOf('maxPrice') != -1 || currentUrl.indexOf('minAcres') != -1)*/
let mapZips = [];
let mapLoaded = false;
let mapsApiLoaded = false;
let clickLoad = false;

export let map;

export function setupSearchAd() {
    const filterBtn = document.querySelector('.search-ad__search-bar .icon--filter');
    const adFilter = document.querySelector('.search-ad__filter');
    const filterSum = document.querySelector('.search-ad__filter-sum');
    const adList = document.querySelector('.search-ad__list');
    let adItems;
    let filterButton;
    const filters = [];
    const showOnMapBtn = document.querySelector('.search-ad__list-item--show-on-map');

    function clickFilterBtn() {
        if (document.body.classList.contains('js-show-filter')) {
            document.body.classList.remove('js-show-filter');
            disableScrollLock();
        } else {
            document.body.classList.add('js-show-filter');
            enableScrollLock();
        }
    }

    function clickList(e) {
        if (!e.target.closest('a') && !e.target.closest('.search-ad__list-item--show-on-map')) {
            adList.classList.add('js-open');

            /*setTimeout(function() {
                if (adItems.length > 3) {
                    adItems[3].scrollIntoView();
                } else {
                    adItems[adItems.length - 1].scrollIntoView();
                }
            },5);*/
        }
    }

    function updateFilter(updateButtonCount,updateFilterSum) {
        filters.forEach(filter => {
            filter.values = [];

            switch (filter.type) {
                case 'number':
                    if (filter.inputs.length == 2) {
                        if (filter.inputs[0].value.trim() != '') {
                            filter.values.push(parseInt(filter.inputs[0].value.trim()));
                        } else {
                            filter.values.push(0);
                        }

                        if (filter.inputs[1].value.trim() != '') {
                            filter.values.push(parseInt(filter.inputs[1].value.trim()));
                        } else {
                            filter.values.push(999999999);
                        }
                    }

                    break;

                case 'multi':
                    filter.inputs.forEach(input => {
                        if (input.checked) {
                            filter.values.push(input.value);
                        } else {
                            filter.values = filter.values.filter(x => x != input.value);
                        }
                    });

                    break;

                case 'single':
                    filter.inputs.forEach(input => {
                        if (input.value != '*') {
                            if (input.checked) {
                                filter.values = [input.value];
                            }
                        }
                    });

                    break;

                default:
                    console.log(`missing update filter type '${filter.type}`);

                    break;
            }
        });

        if (updateButtonCount) {
            let counter = 0;

            adItems.forEach(ad => {
                let showAd = true;

                filters.forEach(filter => {
                    const attributeValue = ad.getAttribute(filter.attribute);

                    if (filter.values.length > 0) {
                        switch (filter.type) {
                            case 'number':
                                if (attributeValue < filter.values[0] || attributeValue > filter.values[1]) {
                                    showAd = false;
                                }

                                break;

                            case 'multi':
                                let hasAny = false;

                                for (let a = 0; a < filter.values.length; a++) {
                                    if (attributeValue.indexOf(filter.values[a]) != -1) {
                                        hasAny = true;
                                    }
                                }

                                if (!hasAny) {
                                    showAd = false;
                                }

                                break;

                            case 'single':
                                if (attributeValue.indexOf(filter.values[0]) == -1) {
                                    showAd = false;
                                }

                                break;
                        }
                    }
                });

                if (showAd) {
                    counter++;
                }
            });

            let btnText = filterButton.getAttribute('data-count-text');

            if (btnText) {
                btnText = btnText.replace("[x]", counter);
                filterButton.textContent = btnText;
            }
        }

        if (updateFilterSum) {
            filterSum.textContent = '';

            filters.forEach(filter => {
                switch (filter.type) {
                    case 'number':
                        if (filter.inputs == 2) {
                            const sumElm = document.createElement('span');
                            sumElm.classList.add('search-ad__filter-sum__item');
                            sumElm.setAttribute('data-filter-ids',`${filter.inputs[0].id},${filter.inputs[1].id}`);
                            sumElm.setAttribute('data-type',filter.type);
                            sumElm.textContent = `${filter.inputs[0].value} - ${filter.inputs[1].value}`;

                            filterSum.appendChild(sumElm);
                        }

                        break;

                    case 'multi':
                        filter.inputs.forEach(input => {
                            if (input.checked && input.value != '*') {
                                const sumElm = document.createElement('span');
                                sumElm.classList.add('search-ad__filter-sum__item');
                                sumElm.setAttribute('data-filter-ids', input.id);
                                sumElm.setAttribute('data-type', filter.type);
                                sumElm.textContent = input.value;

                                filterSum.appendChild(sumElm);
                            }
                        });

                        break;

                    case 'single':
                        filter.inputs.forEach(input => {
                            if (input.checked && input.value != '*') {
                                const sumElm = document.createElement('span');
                                sumElm.classList.add('search-ad__filter-sum__item');
                                sumElm.setAttribute('data-filter-ids', input.id);
                                sumElm.setAttribute('data-type', filter.type);
                                sumElm.textContent = input.value;

                                filterSum.appendChild(sumElm);
                            }
                        });

                        break;
                }
            });
        }
    }

    function resetFilter() {
        const btnText = filterButton.getAttribute('data-text');

        filters.forEach(filter => {
            switch (filter.type) {
                case 'number':
                    filter.inputs.forEach(input => {
                        input.value = '';
                    });

                    break;

                case 'multi':
                    filter.inputs.forEach(input => {
                        input.checked = false;
                    });

                    break;

                case 'single':
                    filter.inputs.forEach(input => {
                        if (input.value == '*') {
                            input.checked = true;
                        } else {
                            input.cheked = false;
                        }
                    });

                    break;
            }
        });

        updateFilter(false,true);
        searchAds();

        if (btnText) {
            filterButton.textContent = btnText;
        }

        if (filterSearchInput) {
            filterSearchInput.value = '';
        }

        filterSearch();

        if (document.body.classList.contains('js-show-filter')) {
            document.body.classList.remove('js-show-filter');
            disableScrollLock();
        }
    }

    function searchAds() {
        mapZips = [];

        if (typeof(arguments[0]) != 'boolean') {
            if (freeText) {
                freeText.value = '';
            }
        }

        updateFilter(false,true);

        adItems.forEach(function(ad, index) {
            let showAd = true;

            filters.forEach(filter => {
                const attributeValue = ad.getAttribute(filter.attribute);

                if (filter.values.length > 0) {
                    switch (filter.type) {
                        case 'number':
                            if (attributeValue < filter.values[0] || attributeValue > filter.values[1]) {
                                showAd = false;
                            }

                            break;

                        case 'multi':
                            let hasAny = false;

                            for (let a = 0; a < filter.values.length; a++) {
                                if (attributeValue.indexOf(filter.values[a]) != -1) {
                                    hasAny = true;
                                }
                            }

                            if (!hasAny) {
                                showAd = false;
                            }

                            break;

                        case 'single':
                            if (attributeValue.indexOf(filter.values[0]) == -1) {
                                showAd = false;
                            }

                            break;
                    }
                }
            });

            if (!showAd) {
                ad.classList.add('js-hide');

                if (adItemObjects[index]) {
                    if (adItemObjects[index].mapObject) {
                        adItemObjects[index].mapObject.setMap(null);
                    }
                }
            } else {
                ad.classList.remove('js-hide');

                if (adItemObjects[index]) {
                    if (adItemObjects[index].mapObject) {
                        if (adItemObjects[index].type == 'zipcode' && mapZips.indexOf(adItemObjects.zipCode) == -1) {
                            adItemObjects[index].mapObject.setMap(map);
                            mapZips.push(adItemObjects.zipCode);
                        }
                    }
                }
            }
        });

        if (document.body.classList.contains('js-show-filter')) {
            document.body.classList.remove('js-show-filter');
            disableScrollLock();
        }

        adList.classList.add('js-open');
    }

    if (adFilter && adList) {
        filterButton = adFilter.querySelector('.btn--primary');
        adItems = Array.from(adList.querySelectorAll('.search-ad__list-item'));

        if (filterButton && adItems.length > 0) {
            const filterWraps = Array.from(adFilter.querySelectorAll('.search-ad__filter-wrap'));

            filterWraps.forEach(filterWrap => {
                const filterAttribute = filterWrap.getAttribute('data-filter-attribute');
                const filterType = filterWrap.getAttribute('data-filter-type');
                const filterInputs = Array.from(filterWrap.querySelectorAll('input'));

                filters.push({
                    attribute: filterAttribute,
                    type: filterType,
                    inputs: filterInputs,
                    values: []
                });

                switch (filterType) {
                    case 'number':
                        let updateTimer;

                        filterInputs.forEach(input => {
                            input.addEventListener('keyup',function() {
                                clearTimeout(updateTimer);

                                updateTimer = setTimeout(function() {
                                    updateFilter(true,false);
                                },250);
                            });
                        });

                        break;

                    case 'multi':
                        filterInputs.forEach(input => {
                            input.addEventListener('change',function() {
                                updateFilter(true,false);
                            });
                        });

                        break;

                    case 'single':
                        filterInputs.forEach(input => {
                            input.addEventListener('change', function() {
                                updateFilter(true,false);
                            });
                        });

                        break;
                }
            });

            filterButton.addEventListener('click', searchAds);
        }

        const filterResets = Array.from(adFilter.querySelectorAll('[data-action="filter-reset"]'));

        filterResets.forEach(reset => {
            reset.addEventListener('click',resetFilter);
        });

        adList.addEventListener('click',clickList);
    }

    const filterSearchInput = document.querySelector('#filterSearch');

    function filterSearch() {
        const formFields = Array.from(adFilter.querySelectorAll('.form__field'));

        if (filterSearchInput.value.trim() != '') {
            adFilter.classList.add('js-search');

            formFields.forEach(field => {
                if (field.textContent.toLowerCase().indexOf(filterSearchInput.value.toLowerCase()) != -1) {
                    field.classList.add('js-show');
                } else {
                    field.classList.remove('js-show');
                }
            });
        } else {
            adFilter.classList.remove('js-search');

            formFields.forEach(field => {
                field.classList.remove('js-show');
            });
        }
    }

    if (filterSearchInput) {
        let filterTimer;

        filterSearchInput.addEventListener('keyup', function () {
            clearTimeout(filterTimer);

            filterTimer = setTimeout(function() {
                filterSearch();
            }, 150);
        });
    }

    const freeText = document.querySelector('#freetext');

    function freetextSearch() {
        const searchValue = freeText.value.trim().toLowerCase();

        adItems.forEach(ad => {
            if (searchValue != '') {
                let hasText = false;
                let hasData = false;

                if (ad.textContent.toLowerCase().indexOf(searchValue) != -1) {
                    hasText = true;
                }

                const adData = JSON.parse(JSON.stringify(ad.dataset));

                if (adData.province.indexOf(searchValue) != -1 || adData.zipcode.indexOf(searchValue) != -1) {
                    hasData = true;
                }

                if (hasText || hasData) {
                    ad.classList.remove('js-hide');
                } else {
                    ad.classList.add('js-hide');
                }
            } else {
                ad.classList.remove('js-hide');
            }
        });

        if (searchValue != '' && !adList.classList.contains('js-open')) {
            adList.classList.add('js-open');
        }
    }

    if (freeText) {
        let freetextTimer;

        freeText.addEventListener('keyup',function() {
            clearTimeout(freetextTimer);

            freetextTimer = setTimeout(function() {
                freetextSearch();
            },250);
        });
    }

    function clickFilterSum(e) {
        if (e.target.closest('.search-ad__filter-sum__item') != undefined) {
            const item = e.target.closest('.search-ad__filter-sum__item');
            const filterIds = item.getAttribute('data-filter-ids').split(',');

            item.parentNode.removeChild(item);

            for (let a = 0; a < filterIds.length; a++) {
                const filterElement = document.querySelector(`#${filterIds[a]}`);

                if (filterElement) {
                    filterElement.checked = false;
                }
            }
        }

        updateFilter(true, false);
    }

    function hideMaps() {
        const openMaps = Array.from(document.querySelectorAll('[data-action="map"].js-show'));

        openMaps.forEach(map => {
            map.classList.remove('js-show');
        });
    }

    function showMaps() {
        const openMaps = Array.from(document.querySelectorAll('[data-action="map"]'));

        openMaps.forEach(map => {
            map.classList.add('js-show');
        });
    }

    if (filterSum) {
        filterSum.addEventListener('click',clickFilterSum);
    }

    if (filterBtn != undefined) {
        filterBtn.addEventListener('click', clickFilterBtn);
    }

    if (showOnMapBtn != undefined && !mapLoaded) {
        showOnMapBtn.addEventListener('click', function () {
            if (showOnMapBtn.classList.contains('js-hide-map')) {
                showOnMapBtn.classList.remove('js-hide-map');
                hideMaps();
                disableScrollLock();
                showOnMapBtn.textContent = showOnMapBtn.getAttribute('data-default-text');
            } else {
                showOnMapBtn.classList.add('js-hide-map');

                if (!mapLoaded) {
                    clickLoad = true;
                    setupMap();
                    enableScrollLock();
                } else {
                    showMaps();
                }

                showOnMapBtn.textContent = showOnMapBtn.getAttribute('data-toggle-text');
            }
        });
    }

    const checkMapLoaded = setInterval(() => {
        if ((mapLoaded && mapSetup) || window.innerWidth < 1500) {
            searchWithQueryParameters();
            clearInterval(checkMapLoaded);
        }
    }, 10);

    function searchWithQueryParameters() {
        if (searchOnLoad) {
            const hasFreeText = currentUrl.indexOf('freeText') != -1;
            /*const hasPrice = currentUrl.indexOf('maxPrice') != -1;
            const hasAcres = currentUrl.indexOf('minAcres') != -1;*/

            let freeText = hasFreeText ? currentUrl.substring(currentUrl.indexOf('freeText') + 9) : '';
            /*let maxPrice = hasPrice ? currentUrl.substring(currentUrl.indexOf('maxPrice') + 9) : '';
            let minAcres = hasAcres ? currentUrl.substring(currentUrl.indexOf('minAcres') + 9) : '';*/

            freeText = freeText.indexOf('&') != -1 ? freeText.substring(0, freeText.indexOf('&')) : freeText;
            /*maxPrice = maxPrice.indexOf('&') != -1 ? maxPrice.substring(0, maxPrice.indexOf('&')) : maxPrice;
            minAcres = minAcres.indexOf('&') != -1 ? minAcres.substring(0, minAcres.indexOf('&')) : minAcres;*/

            const freeTextField = document.querySelector('input[name="freeText"]');
            /*const maxPriceField = document.querySelector('input[name="maxPrice"]');
            const minAcresField = document.querySelector('input[name="minAcres"]');*/

            let doSearch = false;

            if (freeTextField && freeText.length > 0) {
                freeTextField.value = freeText;
                doSearch = true;
            }

            /*if (maxPriceField && maxPrice.length > 0) {
                maxPriceField.value = maxPrice;
                doSearch = true;
            }

            if (minAcresField && minAcres.length > 0) {
                minAcresField.value = minAcres;
                doSearch = true;
            }*/

            if (doSearch) {
                /*updateFilter(true, true);
                searchAds(false);*/
                freetextSearch();
            }
        }
    }
}

export function setupMap() {
    const mapContainers = Array.from(document.querySelectorAll('[data-action="map"]'));

    mapContainers.forEach(mapContainer => {
        let mapElm;
        let mapCenter;
        let mapZoomlevel;
        let mapType;
        let mapElements;

        function initMap() {
            map = new google.maps.Map(mapElm, {
                zoom: mapZoomlevel,
                center: {
                    lat: parseFloat(mapCenter[0]),
                    lng: parseFloat(mapCenter[1])
                },
                mapTypeId: mapType,
                mapTypeControl: false,
                streetViewControl: false
            });

            const contentString = `
                Der er ikke angivet et specifikt område eller adresse for denne jagt. Der kan derfor være flere jagtannoncer indenfor dette område.
                Brug søgningen eller filtreringen til at finde frem til den rette jagt for dig`;

            const infowindow = new google.maps.InfoWindow({
                content: contentString,
            });

            mapElements.forEach(function(mapElement, index) {
                if (mapElement.getAttribute('data-map-data') != '') {
                    const type = mapElement.getAttribute('data-map-data-type');
                    const zipCode = mapElement.getAttribute('data-zipcode');
                    const mapDetail = mapElement.hasAttribute('data-map-detail');
                    //const markerIcon = 'https://sheerhunt.dk/assets/svg/sheerhunt_pin_2.svg';
                    const markerIcon = 'https://sheerhunt.dk/assets/img/sheerhunt_pin.png';

                    switch (type) {
                        case 'address':
                            const markerCoordinate = mapElement.getAttribute('data-map-data').split(',');
                            const marker = new google.maps.Marker({
                                position: { lat: parseFloat(markerCoordinate[0]), lng: parseFloat(markerCoordinate[1])},
                                icon: markerIcon
                            });

                            marker.addListener('click', function () {
                                const link = mapElements[index].querySelector('.search-ad__link');

                                if (clickLoad) {
                                    infowindow.setContent(`<p><b>${mapElements[index].querySelector('h2').textContent}</b><br />${mapElements[index].querySelector('p:last-child').textContent}</p><p><a href="${link.href}">Læs mere</a>`);
                                    infowindow.setPosition(marker.getPosition());
                                    infowindow.open(map);
                                } else {
                                    mapElements[index].scrollIntoView();

                                    link.focus();

                                    setTimeout(function () {
                                        link.blur();
                                    }, 2000);
                                }
                            });

                            marker.setMap(map);
                            adItemObjects.push({mapElement:mapElement,zipCode:zipCode,type:type,mapObject:marker});

                            break;

                        case 'zipcode':
                            const circleCoordinate = mapElement.getAttribute('data-map-data').split(',');
                            const cityCircle = new google.maps.Circle({
                                strokeColor: "#A38744",
                                strokeOpacity: 1,
                                strokeWeight: 1,
                                fillColor: "#A38744",
                                fillOpacity: 0.5,
                                center: { lat: parseFloat(circleCoordinate[0]), lng: parseFloat(circleCoordinate[1])},
                                radius: 1250,
                                clickable: true
                            });

                            cityCircle.addListener('click', function() {
                                infowindow.setContent(contentString);
                                infowindow.setPosition(cityCircle.getCenter());
                                infowindow.open(map);
                            });

                            if (mapZips.indexOf(zipCode) == -1) {
                                cityCircle.setMap(map);
                                mapZips.push(zipCode);
                            }

                            adItemObjects.push({mapElement:mapElement,zipCode:zipCode,type:type,mapObject:cityCircle});

                            break;

                        case 'path':
                            let mapCoordinates = mapElement.getAttribute('data-map-data');
                            const coordinates = [];

                            if (!mapDetail) {
                                let _marker;

                                if (mapCoordinates.indexOf('[') != -1) {
                                    let mapPaths = mapCoordinates.substring(1, mapCoordinates.length - 1);
                                    mapPaths = mapPaths.split('],[');
                                    const firstPathCoordinates = mapPaths[0].substring(1, mapPaths[0].length - 1).split('),(');
                                    const cArray = firstPathCoordinates[0].split(',');

                                    _marker = new google.maps.Marker({
                                        position: new google.maps.LatLng(parseFloat(cArray[0]), parseFloat(cArray[1])),
                                        icon: markerIcon
                                    });
                                } else {
                                    mapCoordinates = mapCoordinates.substring(1, mapCoordinates.length - 1);
                                    mapCoordinates = mapCoordinates.split('),(');
                                    coordinates[0] = [];
                                    const cArray = mapCoordinates[0].split(',');

                                    _marker = new google.maps.Marker({
                                        position: new google.maps.LatLng(parseFloat(cArray[0]), parseFloat(cArray[1])),
                                        icon: markerIcon
                                    });
                                }

                                _marker.addListener('click', function () {
                                    const link = mapElements[index].querySelector('.search-ad__link');

                                    if (clickLoad) {
                                        infowindow.setContent(`<p><b>${mapElements[index].querySelector('h2').textContent}</b><br />${mapElements[index].querySelector('p:last-child').textContent}</p><p><a href="${link.href}">Læs mere</a>`);
                                        infowindow.setPosition(_marker.getPosition());
                                        infowindow.open(map);
                                    } else {
                                        mapElements[index].scrollIntoView();

                                        link.focus();

                                        setTimeout(function () {
                                            link.blur();
                                        }, 2000);
                                    }
                                });

                                _marker.setMap(map);

                                adItemObjects.push({mapElement:mapElement,zipCode:zipCode,type:type,mapObject:marker});
                            } else {
                                if (mapCoordinates.indexOf('[') != -1) {
                                    let mapPaths = mapCoordinates.substring(1, mapCoordinates.length - 1);
                                    mapPaths = mapPaths.split('],[');

                                    mapPaths.forEach(mapPath => {
                                        let pathCoordinates = mapPath.substring(1,mapPath.length - 1);
                                        pathCoordinates = pathCoordinates.split('),(');
                                        coordinates[coordinates.length] = [];

                                        pathCoordinates.forEach(pathCoordinate => {
                                            const cArray = pathCoordinate.split(',');
                                            const c = new google.maps.LatLng(cArray[0], cArray[1]);
                                            coordinates[coordinates.length - 1].push(c);
                                        });
                                    });
                                } else {
                                    mapCoordinates = mapCoordinates.substring(1, mapCoordinates.length - 1);
                                    mapCoordinates = mapCoordinates.split('),(');
                                    coordinates[0] = [];

                                    mapCoordinates.forEach(mapCoordinate => {
                                        const cArray = mapCoordinate.split(',');
                                        const c = new google.maps.LatLng(cArray[0], cArray[1]);
                                        coordinates[0].push(c);
                                    });
                                }

                                if (coordinates.length > 0) {
                                    coordinates.forEach(coordinate => {
                                        const path = new google.maps.Polygon({
                                            paths: coordinate,
                                            strokeColor: '#A38744',
                                            strokeOpacity: 1,
                                            strokeWeight: 1,
                                            fillColor: '#A38744',
                                            fillOpacity: 0.5,
                                            clickable: true
                                        });

                                        path.addListener('click', function () {
                                            const link = mapElements[index].querySelector('.search-ad__link');

                                            if (clickLoad) {
                                                infowindow.setContent(`<p><b>${mapElements[index].querySelector('h2').textContent}</b><br />${mapElements[index].querySelector('p:last-child').textContent}</p><p><a href="${link.href}">Læs mere</a>`);
                                                infowindow.setPosition(path.getPath().getAt(0));
                                                infowindow.open(map);
                                            } else {
                                                mapElements[index].scrollIntoView();

                                                link.focus();

                                                setTimeout(function () {
                                                    link.blur();
                                                }, 2000);
                                            }
                                        });

                                        path.setMap(map);
                                        adItemObjects.push({
                                            mapElement: mapElement,
                                            zipCode: zipCode,
                                            type: type,
                                            mapObject: path
                                        });
                                    });

                                    /*const path = new google.maps.Polygon({
                                        paths: coordinates,
                                        strokeColor: '#A38744',
                                        strokeOpacity: 1,
                                        strokeWeight: 1,
                                        fillColor: '#A38744',
                                        fillOpacity: 0.5,
                                        clickable: true
                                    });*/

                                    /*path.addListener('click', function () {
                                        const link = mapElements[index].querySelector('.search-ad__link');
                                        if (clickLoad) {
                                            infowindow.setContent(`<p><b>${mapElements[index].querySelector('h2').textContent}</b><br />${mapElements[index].querySelector('p:last-child').textContent}</p><p><a href="${link.href}">Læs mere</a>`);
                                            infowindow.setPosition(path.getPath().getAt(0));
                                            infowindow.open(map);
                                        } else {
                                            mapElements[index].scrollIntoView();
                                            link.focus();
                                            setTimeout(function() {
                                                link.blur();
                                            },2000);
                                        }
                                    });
                                    path.setMap(map);
                                    adItemObjects.push({mapElement:mapElement,zipCode:zipCode,type:type,mapObject:path});*/
                                } else {
                                    adItemObjects.push({mapElement:mapElement,zipCode:zipCode,type:type,mapObject:null});
                                }
                            }

                            if (coordinates.length > 0) {
                                coordinates.forEach(coordinate => {
                                    const path = new google.maps.Polygon({
                                        paths: coordinate,
                                        strokeColor: '#A38744',
                                        strokeOpacity: 1,
                                        strokeWeight: 1,
                                        fillColor: '#A38744',
                                        fillOpacity: 0.5,
                                        clickable: true
                                    });

                                    path.addListener('click', function () {
                                        const link = mapElements[index].querySelector('.search-ad__link');

                                        if (clickLoad) {
                                            infowindow.setContent(`<p><b>${mapElements[index].querySelector('h2').textContent}</b><br />${mapElements[index].querySelector('p:last-child').textContent}</p><p><a href="${link.href}">Læs mere</a>`);
                                            infowindow.setPosition(path.getPath().getAt(0));
                                            infowindow.open(map);
                                        } else {
                                            mapElements[index].scrollIntoView();

                                            link.focus();

                                            setTimeout(function () {
                                                link.blur();
                                            }, 2000);
                                        }
                                    });

                                    /*path.setMap(map);
                                    adItemObjects.push({
                                        mapElement: mapElement,
                                        zipCode: zipCode,
                                        type: type,
                                        mapObject: path
                                    });*/
                                });

                                /*const path = new google.maps.Polygon({
                                    paths: coordinates,
                                    strokeColor: '#A38744',
                                    strokeOpacity: 1,
                                    strokeWeight: 1,
                                    fillColor: '#A38744',
                                    fillOpacity: 0.5,
                                    clickable: true
                                });*/

                                /*path.addListener('click', function () {
                                    const link = mapElements[index].querySelector('.search-ad__link');

                                    if (clickLoad) {
                                        infowindow.setContent(`<p><b>${mapElements[index].querySelector('h2').textContent}</b><br />${mapElements[index].querySelector('p:last-child').textContent}</p><p><a href="${link.href}">Læs mere</a>`);
                                        infowindow.setPosition(path.getPath().getAt(0));
                                        infowindow.open(map);
                                    } else {
                                        mapElements[index].scrollIntoView();

                                        link.focus();

                                        setTimeout(function() {
                                            link.blur();
                                        },2000);
                                    }
                                });

                                path.setMap(map);
                                adItemObjects.push({mapElement:mapElement,zipCode:zipCode,type:type,mapObject:path});*/
                            } else {
                                adItemObjects.push({mapElement:mapElement,zipCode:zipCode,type:type,mapObject:null});
                            }

                            break;
                    }
                }
            });

            if (adItemObjects.length == 1) {
                const adItemObject = adItemObjects[0];

                switch (adItemObject.type) {
                    case 'address':
                        map.setCenter(adItemObject.mapObject.getPosition());

                        break;

                    case 'zipcode':
                        map.setCenter(adItemObject.mapObject.getCenter());

                        break;

                    case 'path':
                        map.setCenter(adItemObject.mapObject.getPath().getAt(0));

                        break;
                }
            }

            if (clickLoad && !mapElm.classList.contains('js-show')) {
                mapElm.classList.add('js-show');
            }

            mapSetup = true;
        }

        mapElm = document.body.querySelector(mapContainer.getAttribute('data-map-target'));

        if (mapElm) {
            const force = mapElm.getAttribute('data-map-force');
            const noElements = mapContainer.hasAttribute('data-map-no-elements');
            mapCenter = mapContainer.getAttribute('data-map-center').split(',');
            mapZoomlevel = parseInt(mapContainer.getAttribute('data-map-zoomlevel'));
            mapType = mapContainer.getAttribute('data-map-type');
            mapElements = Array.from(mapContainer.querySelectorAll('[data-map-data]'));

            if (mapElements.length > 0 || noElements) {
                if (!mapsApiLoaded) {
                    const mapsApi = document.createElement('script');
                    mapsApi.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCDRrOr-ofLJUIsDBiVaiyq7ZUtntoeitE';
                    mapsApi.async = true;
                    mapsApi.defer = true;
                    document.body.appendChild(mapsApi);
                    mapsApiLoaded = true;

                    if (window.innerWidth >= 1500 || force || clickLoad) {
                        mapLoaded = true;
                        mapsApi.addEventListener('load', initMap);
                    }
                } else {
                    initMap();
                }
            }
        }
    });
}
