export function setupGalleries() {
    const galleries = document.querySelectorAll('.gallery');

    function prevItem(e) {
        const galleryList = e.currentTarget.closest('.gallery').querySelector('.gallery__list');

        if (galleryList.hasAttribute('data-active-index')) {
            const currentIndex = parseInt(galleryList.getAttribute('data-active-index'));

            if (currentIndex > 0) {
                galleryList.setAttribute('data-active-index', currentIndex - 1);
            }
        }
    }

    function nextItem(e) {
        const galleryList = e.currentTarget.closest('.gallery').querySelector('.gallery__list');

        if (galleryList.hasAttribute('data-active-index')) {
            const galleryItems = galleryList.querySelectorAll('.gallery__item');
            const currentIndex = parseInt(galleryList.getAttribute('data-active-index'));

            if (currentIndex + 1 < galleryItems.length) {
                galleryList.setAttribute('data-active-index', currentIndex + 1);
            }
        }
    }

    galleries.forEach(gallery =>  {
        const galleryList = gallery.querySelector('.gallery__list');
        const galleryItems = gallery.querySelectorAll('.gallery__item');
        const galleryPrev = gallery.querySelector('.gallery__prev');
        const galleryNext = gallery.querySelector('.gallery__next');

        if (
            galleryList &&
            galleryItems.length > 0 &&
            galleryPrev &&
            galleryNext
        ) {
            galleryPrev.addEventListener('click', prevItem);
            galleryNext.addEventListener('click', nextItem);
        }
    });
}