import { isVisible } from '../utils/elementProperties';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';

let activeOverlay;
let activeOverlayContent;
let activeOverlayCloseBtn;

const openClass = 'overlay--active';

function outsideClickListener(e) {
    if (!activeOverlayContent.contains(e.target) && isVisible(activeOverlayContent)) {
        // or use: event.target.closest(selector) === null
        overlayClose(e);
        activeOverlay.removeEventListener('click', outsideClickListener);
    }
}

function overlayOpen(e, lockScroll = true) {
    if (activeOverlay !== undefined) {
        overlayClose(e);
    }

    const targetId = e.target ? e.target.getAttribute('data-target') : e;
    activeOverlay = document.getElementById(targetId);

    typeof e.preventDefault == 'function' ? e.preventDefault() : null;

    if (activeOverlay) {
        activeOverlay.classList.add(openClass);
        activeOverlayContent = activeOverlay.querySelector('.overlay__box');

        activeOverlayCloseBtn = activeOverlay.querySelector('[data-action="overlay-close"]');
        activeOverlayCloseBtn.addEventListener('click', overlayClose);

        if (lockScroll) {
            enableScrollLock();
        }


        if (activeOverlayContent) {
            activeOverlay.addEventListener('click', outsideClickListener);
        }
    } else {
        console.warn(`Overlay data-target missing or no element with id: ${targetId} found`);
    }
}

function overlayClose(e) {
    e.preventDefault();
    disableScrollLock();
    activeOverlay.classList.remove(openClass);
    activeOverlayCloseBtn.removeEventListener('click', overlayClose);
}

export function setupOverlay(selector = '[data-action="overlay-open"]') {
    const overlayButtonOpen = document.body.querySelectorAll(selector);

    for (let i = 0; i < overlayButtonOpen.length; i++) {
        overlayButtonOpen[i].addEventListener('click', overlayOpen);
    }

    if (window.location.hash.indexOf('o=')) {
        const hashOverlay = document.getElementById(window.location.hash);

        if (hashOverlay) {
            overlayOpen(hashOverlay);
        }
    }

    console.log('test 1');
    window.location.onhashchange = function() {
        console.log('test 2');
        const hashOverlay = document.getElementById(window.location.hash);

        console.log(hashOverlay);

        if (hashOverlay) {
            overlayOpen(hashOverlay);
        }
    };
}
