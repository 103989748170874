import { onWindowResize, currentBreakpoint, currentBreakpointIndex } from './utils/windowResize';
import { onScroll, scrollTop } from './utils/scroll';
import { onReady } from './utils/onReady';
import { setupNav } from './components/nav';
import { setupLazyLoading } from './utils/lazyImage';
import { setupAccordions } from './components/accordion';
//import { setupVideos } from './components/video';
import { initVhUnitOverwrite, debounce } from './utils/helpers';
//import { version } from '../../package.json';
//import { setupAnchorLinkScroll } from './components/anchors';
//import { setupFilters } from './components/filter';
import { setupOverlay } from './components/overlay';
//import { setupTabs } from './components/tabs';
//import { setupYTVideos } from './components/video-yt';
//import { setupSliders } from './components/slider';
import { watchFormFields } from './components/form';
import { setupCreateAd } from './components/create-ad';
import { setupSearchAd, setupMap } from './components/search-ad';
import { setupChat } from './components/chat';
import { setupGalleries } from './components/gallery';
import { setupListView } from './components/list-view';

// Below are example of how to use import libraries installed via npm.
// import sal from 'sal.js';

function init() {
    document.body.classList.remove('standby');

    //setupAnchorLinkScroll();

    setupNav('.nav');

    onReady(() => {
        initVhUnitOverwrite();
        setupLazyLoading();
        setupAccordions();

        //setupFilters();

        setupOverlay();

        //setupVideos();

        //setupYTVideos();

        //setupTabs();

        //setupSliders();

        watchFormFields();
        setupMap();
        setupCreateAd();
        setupSearchAd();
        setupChat();
        setupGalleries();
        setupListView();
    });

    // Simulated CookieBot function-call via GTM
    //window.showCookieBanner();

    // onWindowResize event demo
    // I recommend you remove this before going live ;)
    onWindowResize(() => {
        //window.console.log('Resize Event (throuttled)', {currentBreakpoint, currentBreakpointIndex});
    });

    // onScroll event demo
    // Remove when you get tired of the console spam
    let prevScroll = 0;

    onScroll(() => {
        if (scrollTop > prevScroll && scrollTop > 100) {
            document.body.classList.add('js-scrolled');
        } else {
            document.body.classList.remove('js-scrolled');
        }

        prevScroll = scrollTop;
    });

    //console.info(`fong kører - nedkogt til version ${version}`);

    window.addEventListener('load', () => {
        // Polyfill for using svg spritesheet in oldIE
        svg4everybody();
    });
}

init();
